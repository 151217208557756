import React, { Component } from 'react';

let LMap;
let L;

class Map extends Component {

  componentDidMount() {
    /* leaflet import must take place here, when code is
     * guaranteed to not be running on server
     * (react-leaflet uses direct DOM manipulation, no good for SSR)
     */
    /* eslint-disable global-require */
    L = require('react-leaflet');
    /* eslint-enable */
    LMap = L.Map;
    this.forceUpdate();
  }

  render() {
    return (
      LMap ?
        <LMap {...this.props} /> :
        <div />
    );
  }
}

export default Map;

export default {
  is: {
    title: 'Kolefnisreiknivél',
    introText:
      'Reiknivélin sýnir áætlað kolefnisspor vegna flutnings vöru frá upphafsstað til afhendingarstaðar og birtir niðurstöður í kg CO₂-ígilda.',
    originLabel: 'Upphafsstaður',
    originPlaceholder: 'Veldu upphafsstað...',
    destinationLabel: 'Áfangastaður',
    destinationPlaceholder: 'Veldu áfangastað...',
    quantityHeader: 'Magn',
    quantityLabel: 'Fjöldi/magn',
    quantityPlaceholder: 'Veldu magn..',
    unit: 'Mælieining',
    unitPlaceholder: 'Veldu einingu...',
    ton: 'Tonn',
    pallets: 'Vörubretti',
    containers20Ft: '20 feta gámar',
    containers40Ft: '40 feta gámar',
    results: 'Niðurstöður',
    routeNotFound: 'Leið fannst ekki. Sendu okkur endilega línu',
    total: 'Samtals',
    kgco2e: 'kg CO₂í',
    clear: 'Hreinsa',
    aboutCalculator: 'Um Reiknivélina',
    descriptionHTML: `<p>Kolefnisreiknivélin reiknar út áætlaða losun gróðurhúsalofttegunda vegna flutnings með Eimskip.
    Áætlaðri ferð er skipt í flutning með skipum og með vöruflutningabílum. Reiknivélin áætlar losun að gefnum ákveðnum
    forsendum um marga þætti m.a. veðurfar og hleðslu. Niðurstaðan er gefin í
    <a href="https://en.wikipedia.org/wiki/Carbon_dioxide_equivalent">CO₂ ígildum</a>.</p>
    <p>Endanleg losun gróðurhúsalofttegunda vegna flutnings liggur fyrst
    fyrir í lok flutnings og því þarf að nota niðurstöður reiknivélarinnar með þeim fyrirvara að endanleg losun liggur
    ekki fyrir þegar útreikningur er gerður. Frekari upplýsingar um umhverfismál Eimskips er að finna í
    <a href="http://reports.klappir.io/public/env_report/Di4+IFgKAg==">umhverfisskýrslu Eimskips</a>.</p>
    <p>Við viljum gjarnan að reiknivélin verði öflugt verkfæri og ætlum okkur að þróa hana áfram í samvinnu
    við okkar viðskiptavini. Ef þú hefur góðar hugmyndir eða rekst á að það vanti inn flutningaleiðir þá biðjum
    við þig um að senda okkur línu:</p>`,
    name: 'Nafn',
    email: 'Tölvupóstur',
    text: 'Texti',
    sendState: 'Senda upplýsingar um núverandi leit',
    send: 'Senda',
    sending: 'Sendi...',
    sent: 'Sent',
    emailErrorServer:
      'Gat ekki sent skilaboð vegna villu á netþjóni. Vinsamlegast reyndu aftur síðar eða sendu tölvupóst á thjonusta@klappir.com',
    emailErrorNetwork:
      'Gat ekki sent skilaboð vegna netvillu. Athugaðu nettenginguna og reyndu aftur.',
    emailSuccess:
      'Skilaboðin hafa verið send. Takk fyrir að senda okkur álit þitt!',
  },
  en: {
    title: 'Carbon Calculator',
    introText:
      'The calculator shows predicted carbon footprint from transporting goods from a starting point to its destination and shows the results in kg CO₂-equivalent',
    originLabel: 'Starting Point',
    originPlaceholder: 'Select Point of Origin...',
    destinationLabel: 'Destination',
    destinationPlaceholder: 'Select Point of Destination...',
    quantityHeader: 'Quantity',
    quantityLabel: 'Quantity',
    quantityPlaceholder: 'Select Quantity..',
    unit: 'Unit',
    unitPlaceholder: 'Select Unit...',
    ton: 'Metric Ton',
    pallets: 'Pallets',
    containers20Ft: '20 ft Containers',
    containers40Ft: '40 ft Containers',
    results: 'Results',
    routeNotFound: 'Route not found. Plese let us know by sending us a line  ',
    total: 'Total',
    kgco2e: 'kg CO₂e',
    clear: 'Clear',
    aboutCalculator: 'About This Calculator',
    descriptionHTML: `<p>The carbon calculator shows predicted emissions of greenhouse gasses due to transport with Eimskip.
    The trip is divided into land and sea transport and uses averages for certain factors, e.g. weather and load.
    Results are given in kilograms of
    <a href="https://en.wikipedia.org/wiki/Carbon_dioxide_equivalent" tabindex="-1">CO₂ equivalent</a>.</p>

    <p>Final greenhouse gas footprint for the transport is not known until after the voyage so the calculator
    must be used with that fact in mind. More details on Eimskip's environmental matters can be found in
    <a href="http://reports.klappir.io/public/env_report/Di4+IFgKAg==" tabindex="-1">Eimskip's Environmental Report.</a></p>
    <p>We would like the calculator to be a powerful tool for you to use, therefore, we will continue developing
    it in cooperation with our customers. If you have a good idea for the calculator or you cannot find a calculation
    for specific shipping route, we kindly ask you to send us a line:</p>`,
    name: 'Name',
    email: 'Email',
    text: 'Text',
    sendState: 'Send information about current search',
    send: 'Send',
    sending: 'Sending...',
    sent: 'Sent',
    emailErrorServer:
      'Could not send message due to server error. Please try again later, or send an email to service@klappir.com',
    emailErrorNetwork:
      'Could not send message due to network error. Please check your connection and try again.',
    emailSuccess: 'Your message has been sent. Thanks for your feedback!',
  },
};

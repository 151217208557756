import React from 'react';

import { Tooltip, CircleMarker } from 'react-leaflet';
// import Marker from './map/Marker';

const PortMarker = ({ port, selected, onClick, connecting }) => {
  return (
    <CircleMarker
      center={[port.port_latitude, port.port_longitude]}
      onClick={() => !selected && onClick && onClick()}
      color={selected ? '#0176C8' : '#f5b038'}
      radius={selected ? 12 : 7}
      fill={true}
      fillOpacity={1}
    >
      <Tooltip>
        <span>{port.port_name}</span>
      </Tooltip>
    </CircleMarker>
  );
};

export default PortMarker;
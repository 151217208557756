// @flow
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Icon from './Icon';
import EcoSelect from './EcoSelect';
import PlaceInput from './PlaceInput';
import TransportCheckbox from './TransportCheckbox';

import CommentForm from './CommentForm';

import DrivingResults from './DrivingResults';
import ResultLine from './ResultLine';

import CalcMap from './CalcMap';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import polyline from '@mapbox/polyline';
import translations from '../translations';
import { getQueryVariable } from '../getParams';

import { VEHICLE_EMISSION_FACTOR, API_URL } from '../config';

const setNestedState = (key, partialState) => previousState => {
  const part = Object.assign({}, previousState[key], partialState);
  const newState = {};
  newState[key] = part;
  return newState;
};

const findPortName = (key, ports = []) => {
  const port = ports.reduce((prev, curr) => {
    return curr.port_cd === key ? curr : prev;
  }, false);
  return port ? port.port_name : '';
};

type LatLngArr = [number, number];

const getDirections = (origin: LatLngArr, destination: LatLngArr) => {
  // Find driving route to port from Google (via core.klappir.io)
  return fetch(
    `${API_URL}/freight-calculator/directions/?origin=${origin.join(
      ','
    )}&destination=${destination.join(',')}`
  )
    .then(response => response.json())
    .then(data => {
      if (data.routes && data.routes.length > 0) {
        const rawRoute = data.routes[0];
        const route = polyline.decode(rawRoute.overview_polyline.points);
        const distance = rawRoute.legs[0].distance.value;
        const ne = [
          rawRoute.bounds.northeast.lat,
          rawRoute.bounds.northeast.lng,
        ];
        const sw = [
          rawRoute.bounds.southwest.lat,
          rawRoute.bounds.southwest.lng,
        ];
        const bounds = [ne, sw];
        return {
          route,
          distance,
          bounds,
        };
      } else {
        return {
          route: [],
          distance: 0,
        };
      }
    });
};

const defaultBounds = [
  [67.373, -34.541],
  [43.197167, 22.0605468],
];
const defaultPoint = {
  label: '',
  location: '',
  show: false,
  ports: [],
  selectedPort: '',
  distanceFromPort: 0,
  drivingDistance: 0,
  error: '',
  route: [],
};

const defaultState = {
  amount: 0,
  origin: Object.assign({}, defaultPoint),
  destination: Object.assign({}, defaultPoint),
  bounds: defaultBounds,
  unit: '',
  unitObject: null,
  shippingPorts: [],
  shippingRoutes: [],
  drivingRoute: [],
  showInfo: false,
  selectedShippingRoute: 0,
  transportMode: {
    ship: true,
    truck: true,
  },
  error: '',
  panelClosed: false,
  noShippingRouteFound: false,
};

const paddingLeft = parseInt(getQueryVariable('pl'), 10) || 0;

class EcoCalc extends Component {
  constructor() {
    super();
    const pathname = window.location.pathname;
    let lang;
    if (pathname === '/is') {
      lang = 'is';
    } else if (pathname === '/en') {
      lang = 'en';
    } else {
      lang = localStorage.language || 'en';
    }
    localStorage.setItem('language', lang);
    this.state = Object.assign({}, { lang }, defaultState);

    this.setLocation = (key, { lat, lng }, bounds) => {
      // Set location and bounding box
      const otherKey = key === 'origin' ? 'destination' : 'origin';
      const o = {};
      o[key] = this.state[key];
      o[key].location = [lat, lng];
      o[key].show = true;
      if (this.state[otherKey].show) {
        o.bounds = [[lat, lng], this.state[otherKey].location];
      } else {
        o.bounds = bounds;
      }
      this.setState(o);
    };

    this.resetLocation = key =>
      this.setState(setNestedState(key, defaultPoint));

    this.onChange = key => value => {
      this.setState(setNestedState(key, { label: value }));
    };

    this.onChangeAmt = evt => {
      const value = evt.target.value;
      this.setState({ amount: value });
    };

    this.onChangeUnit = (selection: { value: string, label: string }) => {
      console.log(selection);
      this.setState({ unit: selection.value, unitObject: selection });
    };
    this.onBlur = (key: OriginOrDestination) => (evt: {
      target: { value: string },
    }) => {
      this.setState(setNestedState(key, { error: '' }));
      const value = evt.target.value;
      if (value) {
        let bounds = [
          [0, 0],
          [0, 0],
        ];
        geocodeByAddress(value)
          .then(results => {
            console.log('results?', results);
            const { viewport } = results[0].geometry;
            let sw = [0, 0];
            let ne = [0, 0];
            if (viewport) {
              // This is terrible! The reason for this mess is that
              // for some reason the keys in the viewport object
              // keep changing (from i to j to la), this workaround
              // finds they keys in the object and uses them to set
              // the bounds
              // The viewport object essentially looks something like this:
              // viewport: {
              //  sw: {
              //    lat: 12.34,
              //    lng: 12.34
              //  },
              //  ne: {
              //    lat: 12.34,
              //    lng: 12.34
              //  }
              //}
              // and we need to convert it to an array of arrays
              const vpKeys = Object.keys(viewport);
              const latLngKey = Object.keys(viewport[vpKeys[1]]);

              const south = viewport[vpKeys[0]][latLngKey[0]];
              const west = viewport[vpKeys[1]][latLngKey[0]];
              const north = viewport[vpKeys[0]][latLngKey[1]];
              const east = viewport[vpKeys[1]][latLngKey[1]];
              sw = [south, west];
              ne = [north, east];
            }
            bounds = [sw, ne];
            return getLatLng(results[0]);
          })
          .then(({ lat, lng }: LatLngObj) => {
            this.setLocation(key, { lat, lng }, bounds);

            if (this.state.transportMode.ship) {
              // Transport by ship is selected; find nearest ports:
              this.findNearestPorts(key, lat, lng);
            } else {
              const otherKey = key === 'origin' ? 'destination' : 'origin';
              if (this.state.transportMode.truck && this.state[otherKey].show) {
                // Truck transport is selected, let's drive
                this.findDrivingRoute();
              } else {
                // console.log('No modes of transport selected, what to do?')
              }
            }
          })
          .catch(error => console.error(error));
      } else {
        this.resetLocation(key);
      }
    };
    this.findNearestPorts = (key, lat, lng) => {
      fetch(
        `${API_URL}/freight-calculator/nearest-port/?lat=${lat}&lng=${lng}&nports=3`
      )
        .then(response => response.json())
        .then(({ data }) => {
          this.setState(setNestedState(key, { ports: data || [] }));
          if (data && data[0] && data[0].port_cd) {
            // Select the first result as port
            this.onSelectPort(key)({ point: this.state[key], port: data[0] });
          }
        })
        .catch(e => {
          console.error(e);
          this.setState(
            setNestedState(key, {
              error: 'Could not find nearest port due to network error.',
            })
          );
        });
    };

    this.onSelectPort = key => ({ point, port }) => {
      this.setState(
        setNestedState(key, {
          selectedPort: port.port_cd,
          route: [],
          drivingDistance: 0,
        })
      );
      this.clearShippingRoutes();

      if (this.state.transportMode.truck) {
        // Find driving route to port from Google (via core.klappir.io)
        const origin = point.location;
        const destination = [port.port_latitude, port.port_longitude];
        getDirections(origin, destination)
          .then(({ route, distance, bounds: routeBounds }) => {
            if (route.length > 0) {
              this.setState(
                setNestedState(key, {
                  route,
                  drivingDistance: distance,
                })
              );

              // If both points are show, then set a bounding box around points (or if time the whole shebang)
              // and call server to ask for shipping routes
              const { origin, destination } = this.state;
              if (origin.show && destination.show) {
                const locationBounds = [origin.location, destination.location];
                // TODO: Include
                this.setState({ bounds: locationBounds });
                this.findShippingRoutes();
              } else {
                // Else set bounds only around this route
                this.setState({ bounds: routeBounds });
              }
            } else {
              // set error:
              // No drivable way found from location to port
              this.setState(
                setNestedState(key, {
                  route: [],
                  error: `Could not find route from ${point &&
                    point.label} to ${port.port_name}`,
                })
              );
            }
          })
          .catch(e => console.log(e));
      } else {
        this.findShippingRoutes();
      }
    };

    this.onSubmit = e => {
      e.preventDefault();
    };

    this.findShippingRoutes = () => {
      this.setState(state => {
        // Delaying execution of shipping routes call until all state changes
        // have been made to make sure we have correct ports to work with
        const { origin, destination } = state;
        const originPort = origin.selectedPort;
        const destinationPort = destination.selectedPort;
        fetch(
          `${API_URL}/freight-calculator/shipping-routes/?origin=${originPort}&destination=${destinationPort}`
        )
          .then(response => response.json())
          .then(({ data }) => {
            // Save routes in state. Set 0 (best result) as selectedShippingRoute
            this.setState({
              shippingRoutes: data,
              noShippingRouteFound: !(data && data.length > 0),
              selectedShippingRoute: 0,
            });
          })
          .catch(error => {
            this.setState({
              error: 'Could not reach server to calculate shipping route.',
            });
          });

        return {
          noShippingRouteFound: false,
        };
      });
    };
    this.findAllShippingInfo = () => {
      this.setState(state => {
        this.findNearestPorts(
          'origin',
          state.origin.location[0],
          state.origin.location[1]
        );
        this.findNearestPorts(
          'destination',
          state.destination.location[0],
          state.destination.location[1]
        );
      });
    };

    this.selectShippingRoute = index => {
      this.setState({ selectedShippingRoute: index });
    };

    this.getSelectedShippingRoute = () => {
      if (this.state.shippingRoutes && this.state.shippingRoutes.length > 0) {
        return this.state.shippingRoutes[this.state.selectedShippingRoute];
      } else {
        return false;
      }
    };

    this.clearShippingRoutes = () => {
      // console.log('clearShippingRoutes');
      this.setState({
        shippingRoutes: [],
        selectedShippingRoute: 0,
        noShippingRouteFound: false,
      });
    };
    this.clearAllShippingInfo = () => {
      // console.log('clearAllShippingInfo');
      this.setState(state => ({
        origin: {
          ...state.origin,
          route: [],
          ports: [],
          selectedPort: '',
          distanceFromPort: 0,
          drivingDistance: 0,
          error: '',
        },
        destination: {
          ...state.destination,
          route: [],
          ports: [],
          selectedPort: '',
          distanceFromPort: 0,
          drivingDistance: 0,
          error: '',
        },
        shippingRoutes: [],
        selectedShippingRoute: 0,
        noShippingRouteFound: false,
      }));
    };

    this.findDrivingRoute = () => {
      this.setState(state => {
        // Delaying execution of shipping routes call until all state changes
        // have been made to make sure we have correct ports to work with
        const { origin, destination, transportMode } = state;
        if (origin.show && destination.show && transportMode.truck) {
          const key = 'origin';
          getDirections(origin.location, destination.location)
            .then(({ route, distance, bounds }) => {
              if (route.length > 0) {
                this.setState(
                  setNestedState(key, {
                    route,
                    drivingDistance: distance,
                  })
                );
                // set bounds around this route
                this.setState({ bounds });
              } else {
                // set error:
                // No drivable way found from location to port
                this.setState(
                  setNestedState(key, {
                    route: [],
                    error: `Could not find route from ${origin.label} to ${destination.label}`,
                  })
                );
              }
            })
            .catch(e => console.log(e));
        }
      });
    };
    this.clearDrivingRoutes = () => {
      this.setState(state => ({
        origin: {
          ...state.origin,
          distanceFromPort: 0,
          drivingDistance: 0,
          error: '',
          route: [],
        },
        destination: {
          ...state.destination,
          distanceFromPort: 0,
          drivingDistance: 0,
          error: '',
          route: [],
        },
      }));
    };

    this.toggleLang = e => {
      e.preventDefault();
      this.setState(({ lang }) => {
        const newLang = lang === 'is' ? 'en' : 'is';
        localStorage.setItem('language', newLang);
        return { lang: newLang };
      });
    };

    this.toggleInfo = e => {
      e.preventDefault();
      this.setState(({ showInfo }) => ({ showInfo: !showInfo }));
    };
    this.toggleClose = e => {
      e.preventDefault();
      this.setState(({ panelClosed }) => ({ panelClosed: !panelClosed }));
    };

    this.onClear = () =>
      this.setState(Object.assign({}, defaultState, { amount: 0 }));

    this.getUnitMultiplier = (key: string) => {
      const multipliers = {
        t: 1,
        pallets: 0.5,
        teu: 12,
        containers20Ft: 12,
        containers40Ft: 24,
      };

      return multipliers[key];
    };

    this.i18n = key => {
      const lang = this.state.lang;
      return translations[lang][key] || key;
    };

    this.toggleTransport = key => {
      // console.log(key, this.state.transportMode);
      this.setState(({ transportMode }) => {
        if (key === 'ship') {
          if (transportMode.ship) {
            // Untoggling transport by ship; we need to clear shipping routes
            // Using setTimeout because we can't call setState from within setState
            // console.log('clearing shipping routes');
            setTimeout(this.clearAllShippingInfo, 0);
            setTimeout(this.findDrivingRoute, 0);
          } else {
            // console.log('fionsonding shipping routes');
            setTimeout(this.clearAllShippingInfo, 0);
            setTimeout(this.findAllShippingInfo, 0);
          }
        } else if (key === 'truck') {
          if (transportMode.truck) {
            // Toggling transport by truck off; we need to clear driving routes
            // console.log('TODO: Clear driving routes, not ports');
            setTimeout(this.clearDrivingRoutes, 0);
          } else {
            // Toggling transport by truck on
            if (transportMode.ship) {
              setTimeout(this.findAllShippingInfo, 0);
            } else {
              // Find only driving info
              setTimeout(this.findDrivingRoute, 0);
            }
            // console.log('TODO: Find driving routes between origin and originPort, destination and destPort')
          }
        }

        return {
          transportMode: {
            ...transportMode,
            [key]: !transportMode[key],
          },
        };
      });
    };
  }

  state: {
    origin: {},
    destination: {},
    bounds: number[][],
    amount: ?number,
    unit: string,
    unitObject: { label: string, value: string } | null,
    transportMode: {
      ship: boolean,
      truck: boolean,
    },
  };
  onChange: Function;
  onChangeAmt: Function;
  onChangeUnit: Function;
  onBlur: Function;
  onSubmit: Function;
  onClear: Function;
  getUnitMultiplier: Function;

  render() {
    const {
      lang,
      origin,
      destination,
      error,
      shippingRoutes,
      showInfo,
      selectedShippingRoute,
      bounds,
      amount,
      unit,
      unitObject,
      panelClosed,
      transportMode,
    } = this.state;

    return (
      <div
        className={`EcoCalc ${panelClosed ? 'EcoCalc-panel-closed' : ''} ${
          paddingLeft && panelClosed ? 'EcoCalc-panel-hide' : ''
        }`}
        style={{
          height: '100vh',
          position: 'relative',
          paddingLeft: paddingLeft ? paddingLeft + 20 : undefined,
        }}
      >
        <Helmet>
          <title>{this.i18n('title')}</title>
        </Helmet>
        <a
          href="#toggle-panel"
          className="EcoCalc-show-panel"
          onClick={this.toggleClose}
        >
          <Icon
            name="chevronRight"
            className="EcoCalc-panel-closed-icon"
            style={{ width: 15, height: 15, fill: '#0076c9' }}
          />
        </a>

        <div
          className={`map-features-menu ${
            showInfo ? 'EcoCalc-info-active' : ''
          }`}
        >
          <div className="EcoCalc-CloseToggle" />
          <div className="EcoCalc-options">
            <img
              src="/Eimskip2.png"
              style={{ height: 35, marginBottom: 20, marginRight: 'auto' }}
              alt="Eimskip"
            />

            <a
              href={`/${lang === 'is' ? 'en' : 'is'}`}
              className="EcoCalc-options-item"
              onClick={this.toggleLang}
            >
              <span className={lang === 'is' ? 'active' : ''}>is</span> /
              <span className={lang === 'en' ? 'active' : ''}> en</span>
            </a>
            <a
              href="#info"
              className={`EcoCalc-options-item ${showInfo ? 'active' : ''}`}
              onClick={this.toggleInfo}
            >
              <Icon
                name="information"
                style={{ width: 20, height: 20, fill: 'inherit' }}
              />
            </a>
          </div>

          <div className="panel-body">
            <h2 className="panel-heading">{this.i18n('title')}</h2>

            <form onSubmit={this.onSubmit}>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  margin: '0 -10px',
                }}
              >
                <PlaceInput
                  label={this.i18n('originLabel')}
                  value={origin.label}
                  placeholder={this.i18n('originPlaceholder')}
                  error={origin.error}
                  autofocus={true}
                  onChange={this.onChange('origin')}
                  onBlur={this.onBlur('origin')}
                  tabIndex={showInfo ? '-1' : '0'}
                />

                <PlaceInput
                  label={this.i18n('destinationLabel')}
                  value={destination.label}
                  placeholder={this.i18n('destinationPlaceholder')}
                  error={destination.error}
                  autofocus={false}
                  onChange={this.onChange('destination')}
                  onBlur={this.onBlur('destination')}
                  tabIndex={showInfo ? '-1' : '0'}
                />
              </div>

              <div style={{ display: 'flex', marginBottom: 10 }}>
                <div style={{ flex: '1 1 50%', marginRight: '5px' }}>
                  <EcoSelect
                    label="Mælieining"
                    value={unitObject}
                    id="unit"
                    options={[
                      { label: this.i18n('unitPlaceholder'), value: '' },
                      { label: this.i18n('ton'), value: 't' },
                      {
                        label: this.i18n('containers20Ft'),
                        value: 'containers20Ft',
                      },
                      {
                        label: this.i18n('containers40Ft'),
                        value: 'containers40Ft',
                      },
                    ]}
                    onChange={this.onChangeUnit}
                    tabIndex={showInfo ? '-1' : '0'}
                  />
                </div>
                <div style={{ flex: '1 1 50%', marginLeft: '5px' }}>
                  <label className="control-label sr-only" htmlFor="amount">
                    {this.i18n('quantityLabel')}
                  </label>
                  <input
                    value={amount}
                    type="number"
                    className="form-control EcoCalc-input m-b"
                    id="amount"
                    name="account"
                    placeholder={this.i18n('quantityLabel')}
                    onChange={this.onChangeAmt}
                    min="0"
                    tabIndex={showInfo ? '-1' : '0'}
                  />
                </div>
              </div>
              {!!error ? <p>{error}</p> : null}
              <div className="EcoCalc-transport-mode-selection">
                <TransportCheckbox
                  id="truck"
                  label="Include transport by truck"
                  icon="truck2"
                  checked={transportMode.truck}
                  onChange={() => this.toggleTransport('truck')}
                />

                <TransportCheckbox
                  id="ship"
                  label="Include transport by ship"
                  icon="ship2"
                  checked={transportMode.ship}
                  onChange={() => this.toggleTransport('ship')}
                />
              </div>

              {/*<h3>{this.i18n('results')}</h3>*/}
              <ul className="EcoCalc-result">
                <DrivingResults
                  point={origin}
                  amount={amount}
                  show={
                    origin.show && origin.drivingDistance && transportMode.truck
                  }
                  destinationLabel={
                    transportMode.truck &&
                    !transportMode.ship &&
                    destination.show
                      ? destination.label.split(',')[0]
                      : ''
                  }
                  unitMultiplier={this.getUnitMultiplier(unit)}
                  emissionFactor={VEHICLE_EMISSION_FACTOR}
                  pointIsOrigin
                  lang={lang}
                />
                <ResultLine
                  origin={findPortName(origin.selectedPort, origin.ports)}
                  destination={findPortName(
                    destination.selectedPort,
                    destination.ports
                  )}
                  show={origin.show && destination.show && transportMode.ship}
                  iconName="ship2"
                  distance={
                    this.getSelectedShippingRoute() &&
                    this.getSelectedShippingRoute().distance
                  }
                  emission={
                    amount &&
                    unit &&
                    this.getSelectedShippingRoute() &&
                    this.getSelectedShippingRoute().kgco2e *
                      amount *
                      this.getUnitMultiplier(unit)
                  }
                  lang={lang}
                  isNotFound={this.state.noShippingRouteFound}
                />

                <DrivingResults
                  point={destination}
                  amount={amount}
                  show={
                    destination.show &&
                    destination.drivingDistance &&
                    transportMode.ship &&
                    transportMode.truck
                  }
                  unitMultiplier={this.getUnitMultiplier(unit)}
                  emissionFactor={VEHICLE_EMISSION_FACTOR}
                  lang={lang}
                />

                <li className="EcoCalc-result-item EcoCalc-result-summary">
                  <h4 className="EcoCalc-summary-head">
                    <span>{this.i18n('total')}</span>
                  </h4>
                  <p className="EcoCalc-calculations-line">
                    <span className="EcoCalc-calculations-value">
                      <b>
                        {Math.round(
                          (origin.drivingDistance / 1000 || 0) +
                            (destination.drivingDistance / 1000 || 0) +
                            ((this.getSelectedShippingRoute() &&
                              this.getSelectedShippingRoute().distance) ||
                              0)
                        ).toLocaleString(lang === 'is' ? 'de' : 'en')}
                      </b>
                      km
                    </span>
                    {!!amount && !!unit && (
                      <span className="EcoCalc-calculations-value">
                        <b>
                          {Math.round(
                            ((origin.drivingDistance / 1000 || 0) +
                              (destination.drivingDistance / 1000 || 0)) *
                              VEHICLE_EMISSION_FACTOR *
                              amount *
                              this.getUnitMultiplier(unit) +
                              ((this.getSelectedShippingRoute() &&
                                this.getSelectedShippingRoute().kgco2e *
                                  amount *
                                  this.getUnitMultiplier(unit)) ||
                                0)
                          ).toLocaleString(lang === 'is' ? 'de' : 'en')}
                        </b>{' '}
                        {this.i18n('kgco2e')}
                      </span>
                    )}
                  </p>
                </li>
              </ul>
            </form>
          </div>

          <div className="panel-body">
            {/*<h2 className="panel-heading">
              {this.i18n('aboutCalculator')}
            </h2>*/}

            {/* About calculator text
             *  Translation are kept in ../translation.js
             */}
            <div
              className="EcoCalc-info-text"
              dangerouslySetInnerHTML={{ __html: this.i18n('descriptionHTML') }}
            />

            <CommentForm lang={lang} state={this.state} isVisible={showInfo} />
          </div>
        </div>

        <CalcMap
          origin={origin}
          destination={destination}
          bounds={bounds}
          shippingRoutes={shippingRoutes}
          selectedShippingRoute={selectedShippingRoute}
          panelClosed={panelClosed}
          onSelectPort={this.onSelectPort}
          selectShippingRoute={this.selectShippingRoute}
          paddingLeft={paddingLeft}
        />
      </div>
    );
  }
}

export default EcoCalc;

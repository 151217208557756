import React from 'react';
import { Motion, spring } from 'react-motion';

import { API_URL } from '../config';
import translations from '../translations';

class CommentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      text: '',
      sendState: true,
      isSending: false,
      isSent: false,
      error: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      isSent: false,
      error: '',
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, text, sendState } = this.state;
    this.setState({ isSending: true });
    // call API to sendEmail
    fetch(`${API_URL}/freight-calculator/comment`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ name, email, text, state: sendState && this.props.state }),
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response.result === 'OK') {
        this.setState({ isSending: false, isSent: true });
      } else {
        console.error(response);
        this.setState({
          isSending: false,
          isSent: false,
          error: 'emailErrorServer',
        })
      }
    }).catch((e) => {
      console.error(e);
      this.setState({
        isSending: false,
        isSent: false,
        error: 'emailErrorNetwork',
      })
    });
  }

  render() {
    const i18n = (key) => translations[this.props.lang][key];
    const { name, email, text, sendState, isSending, isSent, error } = this.state;
    const { isVisible } = this.props;
    const submitValue = (isSending, isSent) => {
      if(isSending) {
        return i18n('sending');
      }
      if(isSent) {
        return i18n('sent');
      }
      return i18n('send');
    }
    return (
      <form
        onSubmit={this.handleSubmit}
      >
        <label htmlFor="EcoCalc-input-name">{i18n('name')}:</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={this.handleInputChange}
          id="EcoCalc-input-name"
          placeholder={i18n('name')}
          className="EcoCalc-input"
          style={{ marginBottom: '10px' }}
          tabIndex={isVisible ? 0 : -1}
        />

        <label htmlFor="EcoCalc-input-email">{i18n('email')}:</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={this.handleInputChange}
          id="EcoCalc-input-email"
          placeholder={i18n('email')}
          className="EcoCalc-input"
          style={{ marginBottom: '10px' }}
          tabIndex={isVisible ? 0 : -1}
        />
        <textarea
          name="text"
          value={text}
          onChange={this.handleInputChange}
          placeholder={i18n('text')}
          rows="3"
          className="EcoCalc-comment-area"
          tabIndex={isVisible ? 0 : -1}
          style={{
            marginBottom: 10,
            padding: 5,
            fontFamily: 'inherit',
            fontSize: 14,
          }}
        ></textarea>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
          <input
            type="submit"
            className={`btn has-success ${isSent && 'has-success'}`}
            value={submitValue(isSending, isSent)}
            tabIndex={isVisible ? '0' : '-1'}
          />
          <div style={{ marginRight: 'auto' }}>
            <div style={{ marginBottom: 10 }}>
              <input
                type="checkbox"
                name="sendState"
                checked={sendState}
                onChange={this.handleInputChange}
                id="EcoCalc-input-sendState"
                style={{ margin: '0 10px 0 0' }}
                tabIndex={isVisible ? '0' : '-1'}
              />
              <label
                className="EcoCalc-show"
                htmlFor="EcoCalc-input-sendState"
              >
                {i18n('sendState')}
              </label>
            </div>
            <Motion
              defaultStyle={{ height: 0, opacity: 0, }}
              style={{
                height: spring(error || isSent ? 20 : 0),
                opacity: spring(error || isSent ? 1 : 0),
              }}
            >
              {motionStyle =>
                <p
                  style={{
                    height: motionStyle.height,
                    opacity: motionStyle.opacity,
                    display: !!motionStyle.opacity ? 'inline-block' : 'none',
                  }}
                  className={`${error && 'status-error'} ${isSent && 'status-success'} `}
                >
                  {error && i18n(error)}
                  {isSent && i18n('emailSuccess')}
                </p>
              }
            </Motion>
            <a
              href="http://www.klappir.com/"
              style={{ color: 'inherit', textDecoration: 'none' }}
              tabIndex={isVisible ? '0' : '-1'}
            >
              <img
                src="https://static.klappir.io/img/logo/klappir-logo.svg"
                alt="Klappir logo"
                style={{ height: 25, margin: '5px 0 -5px 0' }}
              />
              Klappir
            </a>
          </div>
        </div>
      </form>
    );
  };
}
export default CommentForm;
import React from 'react';
import { Motion, spring } from 'react-motion'

import Icon from './Icon';

import translations from '../translations';

const i18n = (lang, key) => {
  return translations[lang][key];
}

const ResultLine = ({ origin, destination, show, iconName, distance, emission, isNotFound, lang = 'en' }: {
  origin: string,
  destination: string,
  show: boolean,
  iconName: string,
  distance: number,
  emission: number | boolean,
  lang: string,
  isNotFound: boolean,
}) => {
  return (
    <Motion
      defaultStyle={{ height: 0, opacity: 0 }}
      style={{
        height: spring(
          show ? 80 : 0,
        ),
        opacity: spring(
          show ? 1 : 0,
        )
      }}
    >
      {motionStyle =>
        <li
          className="EcoCalc-result-item"
          style={{
              height: motionStyle.height,
              opacity: motionStyle.opacity,
              display: !!motionStyle.opacity ? 'block' : 'none'
            }}
        >
          <div className="EcoCalc-result-icon">
            <Icon name={iconName} style={{ width: 35, height: 35, fill: '#0176C8' }} />
          </div>
          <h4>
            <span className="EcoCalc-result-start">{origin}</span>
            <Icon name="chevronRight" style={{ width: 10, height: 10, fill: '#3498db' }} />
            <span className="EcoCalc-result-end">{destination}</span>
          </h4>
          <p className="EcoCalc-calculations-line">
            {distance ?
              <span className="EcoCalc-calculations-value">
                <b>{Math.round(distance).toLocaleString(lang === 'is' ? 'de' : 'en' )}</b>km
              </span>
            : ''}
            {emission ?
              <span className="EcoCalc-calculations-value">
                <b>{
                  Math.round(emission).toLocaleString(lang === 'is' ? 'de' : 'en' )
                }</b>{i18n(lang, 'kgco2e')}
              </span>
              : ''}
            {isNotFound ?
              <span className="EcoCalc-calculations-value" style={{ maxWidth: 200, color: '#008CFF' }}>
                {i18n(lang, 'routeNotFound')}
              </span>
              : ''}
          </p>
        </li>
      }
    </Motion>);
};

export default ResultLine;
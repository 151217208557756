import React, { Component } from 'react';

let LZoomControl;
let L;

class ZoomControl extends Component {

  componentDidMount() {
    /* leaflet import must take place here, when code is
     * guaranteed to not be running on server
     * (react-leaflet uses direct DOM manipulation, no good for SSR)
     */
    /* eslint-disable global-require */
    L = require('react-leaflet');
    /* eslint-enable */
    LZoomControl = L.ZoomControl;
    this.forceUpdate();
  }

  render() {
    return (
      LZoomControl ?
        <LZoomControl {...this.props} /> :
        <div />
    );
  }
}

export default ZoomControl;

import React from 'react';

import ResultLine from './ResultLine';

const findPortName = (key, ports = []) => {
  const port = ports.reduce((prev, curr) => {
    return curr.port_cd === key ? curr : prev
  }, false);
  return port ? port.port_name : '';
}

const DrivingResults = ({ point, amount, unitMultiplier, emissionFactor, pointIsOrigin, lang, show, destinationLabel }) => (
  <ResultLine
    origin={pointIsOrigin ? point && point.label && point.label.split(',')[0] : findPortName(point.selectedPort, point.ports)}
    destination={destinationLabel ? destinationLabel :
        (!pointIsOrigin ? point && point.label && point.label.split(',')[0] : findPortName(point.selectedPort, point.ports))
    }
    show={show}
    iconName="truck2"
    distance={point.drivingDistance / 1000}
    emission={amount && unitMultiplier && emissionFactor && amount * unitMultiplier * emissionFactor * point.drivingDistance / 1000  }
    lang={lang}
  />);

export default DrivingResults;
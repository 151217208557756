// @flow
import React, { Component } from 'react';

let L;
let rL;
let LMarker;

class Marker extends Component {
  constructor() {
    super();

    this.getIcon = () => undefined;
  }

  componentWillMount() {
    L = require('leaflet');

    this.getIcon = (iconUrl) => {
      if (iconUrl) {
        return L.icon({
          iconUrl,
          iconSize: [38, 38],
          iconAnchor: [19, 38],
          popupAnchor: [0, -15],
        });
      }
      return undefined;
    };
  }

  componentDidMount() {
    /* leaflet import must take place here, when code is
     * guaranteed to not be running on server
     * (react-leaflet uses direct DOM manipulation, no good for SSR)
     */
    /* eslint-disable global-require */
    rL = require('react-leaflet');
    /* eslint-enable */
    LMarker = rL.Marker;

    this.forceUpdate();
  }

  render() {
    const modifiedProps = Object.assign({}, this.props);
    modifiedProps.icon = this.getIcon(this.props.icon);
    return (
      LMarker ?
        <LMarker {...modifiedProps} /> :
        <div />
    );
  }
}

export default Marker;

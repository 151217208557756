import React, { Component } from 'react';
import EcoCalc from './component/EcoCalc';
import './App.css';

class App extends Component {
  render() {
    return (
      <EcoCalc />
    );
  }
}

export default App;

import React from 'react';

import Icon from './Icon';

const TransportCheckbox = ({
  id,
  label,
  icon,
  checked,
  onChange,
}: {
  id: string,
  label: string,
  icon: string,
  checked: boolean,
  onChange: () => void,
}) => (
  <label className={`EcoCalc-transport-checkbox ${checked ? 'checked': ''}`} htmlFor={id}>
      <span className="sr-only">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          id={id}
          name={id}
        />
        Truck
      </span>
      <Icon name={icon} className="EcoCalc-transport-icon" style={{ width: 20, height: 20, fill: 'inherit' }} />
    </label>
);

export default TransportCheckbox;

// @flow
import React from 'react';
import Select from 'react-select';

const EcoSelect = ({
  value,
  label,
  id,
  options,
  onChange,
  tabIndex,
}: {
  value: string,
  label: string,
  id: string,
  options: { label: string, value: string }[],
  onChange: ?Function,
  tabIndex: Number,
}) => (
  <div className="form-group">
    <label className="control-label sr-only" htmlFor={id}>
      {label}
    </label>
    <Select
      value={value}
      name={id}
      options={options}
      onChange={onChange}
      tabIndex={String(tabIndex)}
      styles={{
        control: s => ({
          ...s,
          borderRadius: 0,
          minHeight: 36,
          boxShadow: 'none',
        }),
        dropdownIndicator: s => ({
          ...s,
          padding: '6px 8px',
        }),
        indicatorSeparator: s => ({
          ...s,
          marginTop: 6,
          marginBottom: 6,
        }),
      }}
    />
  </div>
);

export default EcoSelect;

import React from 'react';

import GeoJSON from './map/GeoJSON';

import { Tooltip, CircleMarker } from 'react-leaflet';

const buildGeoJsonLineFromLegs = ({ legs = [], ports = {} }) => {
  const getFeature = (coordinates, lineColor) => {
    return [
      {
        type: 'Feature',
        properties: { lineColor },
        geometry: {
          type: 'LineString',
          coordinates: coordinates.map(item => [item[1], item[0]]),
        },
      },
    ];
  };

  return [].concat.apply(
    [],
    legs.map(leg => {
      let coordinates = [];

      if (leg.calc_route && leg.calc_route.length > 2) {
        coordinates = leg.calc_route;
      } else if (leg.man_route) {
        coordinates = leg.man_route;
      } else if (leg.calc_route) {
        coordinates = leg.calc_route;
      } else {
        // Set line as a straight line between start and end port
        const startPort = ports[leg.start_port];
        const endPort = ports[leg.end_port];
        startPort &&
          coordinates.push([startPort.port_latitude, startPort.port_longitude]);
        endPort &&
          coordinates.push([endPort.port_latitude, endPort.port_longitude]);
      }
      return getFeature(coordinates, leg.lines);
    })
  );
};

const ShippingRoute = ({
  route,
  selected,
  onClick,
}: {
  route: {},
  selected: boolean,
  onClick: Function,
}) => {
  let legs = [];
  let ports = {};

  if (route && route.description) {
    legs = route.description.legs;
    ports = route.description.ports;
  }

  function style(feature) {
    // Color codes for Eimskip's lines (yellow line, blue line etc.)
    const colors = {
      YELLOW: '#ffc900',
      BLUE: '#2e73ff',
      RED: '#db2a1a',
      PURPLE: '#71288f',
      GREEN: '#0AA300',
    };
    // Default leg color is gray (probably never used though).
    let color = 'gray';

    // Set color of this current leg.
    Object.keys(colors).forEach(colorName => {
      if (feature.properties.lineColor.indexOf(colorName) !== -1)
        color = colors[colorName];
    });

    // If multiple lines are possible for this leg, then always choose the line which is the longest.
    if (
      feature.properties.lineColor.indexOf(route.description.longest_line) !==
      -1
    ) {
      color = colors[route.description.longest_line];
    }

    return {
      color,
    };
  }

  return (
    <div>
      {
        <GeoJSON
          data={buildGeoJsonLineFromLegs({ legs, ports })}
          onClick={onClick}
          opacity={selected ? 1 : 0.2}
          weight={5}
          style={style}
        />
      }
      {selected &&
        route &&
        route.description &&
        route.description.ports &&
        Object.keys(route.description.ports).map(port_cd => {
          const port = route.description.ports[port_cd];
          return (
            port &&
            typeof port === 'object' &&
            port.port_latitude &&
            port.port_longitude && (
              <CircleMarker
                key={port_cd}
                center={[port.port_latitude, port.port_longitude]}
                color="white"
                radius={5}
                fill={true}
                fillOpacity={1}
              >
                <Tooltip>
                  <span>{port.port_name}</span>
                </Tooltip>
              </CircleMarker>
            )
          );
        })}
    </div>
  );
};

export default ShippingRoute;

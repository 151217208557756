import React from 'react';

import Map from './map/Map';
import TileLayer from './map/TileLayer';
import ZoomControl from './map/ZoomControl';
import DrivingLeg from './DrivingLeg';
import ShippingRoute from './ShippingRoute';


const CalcMap = ({
  origin,
  destination,
  bounds,
  panelClosed,
  shippingRoutes,
  selectedShippingRoute,
  onSelectPort,
  selectShippingRoute,
  paddingLeft = 0,
}) => (
  <div className="map-layer">
    <Map
      style={{}}
      bounds={bounds}
      boundsOptions={{
        paddingTopLeft: panelClosed ? [paddingLeft, 0] : [paddingLeft + 600, 0],
        paddingBottomRight: [0, 0],
      }}
      zoomControl={false}
      maxZoom={14}
      useFlyTo
    >
      <ZoomControl position="topright" />
      {/*<TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}.png"
        maxZoom={9}
        minZoom={0}
        attribution={'Road routing data ©2017 Google'}
      />*/}
      {/* https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png https://carto.com/location-data-services/basemaps/ */}
      <TileLayer
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        maxZoom={14}
        minZoom={0}
        attribution={'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attribution">CARTO</a> Road routing data ©2017 Google'}
      />
      <DrivingLeg
        point={origin}
        onSelectPort={onSelectPort('origin')}
      />

      <DrivingLeg
        point={destination}
        onSelectPort={onSelectPort('destination')}
      />

      {shippingRoutes && shippingRoutes.map((route, index) => (
        route && <ShippingRoute
          key={route.distance}
          route={route}
          selected={selectedShippingRoute === index}
          onClick={() => selectShippingRoute(index)}
        />
      ))}
    </Map>
  </div>
  );

export default CalcMap;
import React from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';
import { Motion, spring } from 'react-motion';

const PlaceInput = ({ label, placeholder, autofocus, error, value, onChange, onBlur, tabIndex }: {
  label: String,
  placeholder: String,
  value: String,
  error: String,
  autofocus: Boolean,
  onChange: Function,
  onBlur: Function,
}) => (
    <div className="PlaceInput" style={{ flex: '1 1 auto', minWidth: 200 }}>
      {/*<h4>{label}</h4>*/}
      <PlacesAutocomplete
        inputProps={{
          value,
          tabIndex,
          onChange,
          onBlur,
          type: 'search',
          placeholder: placeholder || 'Search Places...',
          autoFocus: autofocus || false,
        }}
        classNames={{
          input: 'EcoCalc-input',
          autocompleteContainer: 'EcoCalc-autocompleteContainer',
          autocompleteItem: 'EcoCalc-autocompleteItem',
          autocompleteItemActive: 'EcoCalc-autocompleteItemActive',
        }}
        styles={{
          root: {
            zIndex: autofocus ? 5 : 4,
            marginBottom: 10,
          },
          input: {
            border: '1px solid #0076c9',
            borderRadius: 0,
            borderRight: autofocus ? 0 : undefined,
            boxShadow: 'none',
            fontSize: 14,
            zIndex: -1,
          },
          autocompleteContainer: {
            top: 'calc(100% - 3px)',
            borderRadius: 0,
            borderTop: 0,
            borderColor: '#0076c9',
            zIndex: 4,
          },
        }}
      />
      <Motion
        defaultStyle={{ height: 0, opacity: 0 }}
        style={{
          height: spring(error ? 20 : 0),
          opacity: spring(error ? 1 : 0),
        }}
      >
        {motionStyle =>
          <p
            style={{
              height: motionStyle.height,
              opacity: motionStyle.opacity,
              display: !!motionStyle.opacity ? 'block' : 'none',
            }}
            className="status-error"
          >
            {error}
          </p>
        }
      </Motion>
    </div>
  );

export default PlaceInput;

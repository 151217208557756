import React from 'react';

import Marker from './map/Marker';
import Polyline from './map/Polyline';
import PortMarker from './PortMarker';

const DrivingLeg = ({
  point,
  onSelectPort,
}) => {
  return (
    <div>
      {point.show && <Marker
        position={point.location}
        icon="https://static.klappir.io/img/icons/placeholder.svg"
      />}


      {point.show && point.route && point.route.length > 0 &&
        <Polyline
          positions={point.route}
          color="#0176C8"
        />
      }

      {point.show && point.ports && point.ports.slice(0).reverse().map(port => (
        <PortMarker
          key={port.port_cd}
          port={port}
          selected={point.selectedPort === port.port_cd}
          onClick={() => onSelectPort({ point, port })}
          />
      )) }
    </div>
  );
};

export default DrivingLeg;

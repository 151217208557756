import React, { Component } from 'react';

let LPolyline;
let L;

class Polyline extends Component {

  componentDidMount() {
    /* leaflet import must take place here, when code is
     * guaranteed to not be running on server
     * (react-leaflet uses direct DOM manipulation, no good for SSR)
     */
    /* eslint-disable global-require */
    L = require('react-leaflet');
    /* eslint-enable */
    LPolyline = L.Polyline;
    this.forceUpdate();
  }

  render() {
    return (
      LPolyline ?
        <LPolyline {...this.props} /> :
        <div />
    );
  }
}

export default Polyline;

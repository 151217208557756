import React, { Component } from 'react';

let LGeoJSON;
let L;

class GeoJSON extends Component {

  componentDidMount() {
    /* leaflet import must take place here, when code is
     * guaranteed to not be running on server
     * (react-leaflet uses direct DOM manipulation, no good for SSR)
     */
    /* eslint-disable global-require */
    L = require('react-leaflet');
    /* eslint-enable */
    LGeoJSON = L.GeoJSON;
    this.forceUpdate();
  }

  render() {
    return (
      LGeoJSON ?
        <LGeoJSON {...this.props} /> :
        <div />
    );
  }
}

export default GeoJSON;
